import React from 'react';
import { Helmet } from 'react-helmet';

function Region() {
 
  return (
    <>
    <Helmet>
     <title>Région - Les Tilleuls</title>
     <meta name="description" content="Découvrez les activités passionantes à prximité de notre hôtel." />
     <meta property="og:title" content="Région - Les Tilleuls" />
     <meta property="og:description" content="Balades, randonnées, lieux historiques... Les Tilleuls offre une large gamme d'activités à procimité." />
     <meta property="og:image" content="/logo.png" />
   </Helmet>
 </>
  );
}

export default Region;
