import React, { useState } from 'react';
import './reservation.css';
import { Helmet } from 'react-helmet';
import emailjs from '@emailjs/browser';

function Reservation() {
  const [reservationData, setReservationData] = useState({
    civility: 'Mr',
    lastName: '',
    firstName: '',
    address: '',
    postalCode: '',
    city: '',
    phone: '',
    email: '',
    checkin: '',
    checkout: '',
    guests: 1,
    roomType: 'standard',
    specialRequests: '',
    bathroomChoice: 'douche',
    bedChoice: '1 lit',
    breakfast: 0,
    dinnerGuests: 1
  });
  const [loading, setLoading] = useState(false);  // État pour le loader

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setReservationData({ ...reservationData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);  // Affiche le loader

    emailjs.sendForm(
      'service_e0caza5',  // Votre Service ID
      'template_taop1sh', // Votre Template ID
      e.target,
      'HqXJ0eAYkI--uqVId' // Votre Public Key
    )
    .then((result) => {
      setLoading(false);  // Cache le loader
      alert('Votre réservation a bien été envoyée !');
    }, (error) => {
      setLoading(false);  // Cache le loader
      alert('Erreur lors de l\'envoi. Veuillez réessayer.');
    });
  };
  
  return (
    <>
      <Helmet>
        <title>Réservation - Les Tilleuls</title>
        <meta name="description" content="Réservez votre séjour dans notre hôtel Les Tilleuls à Saint-Florentin." />
      </Helmet>
      <section className="reservation-section">
        <div className="reservation-form-wrapper">
          <h1>Détails de la réservation</h1>
          <form className="reservation-form" onSubmit={handleSubmit}>
            {/* Champs pour les informations personnelles */}
            <div className="form-group">
              <label htmlFor="civility">Civilité</label>
              <select id="civility" name="civility" value={reservationData.civility} onChange={handleInputChange}>
                <option value="Mr">Mr</option>
                <option value="Mme">Mme</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="lastName">Nom</label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={reservationData.lastName}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="firstName">Prénom</label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={reservationData.firstName}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="address">Adresse</label>
              <input
                type="text"
                id="address"
                name="address"
                value={reservationData.address}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="postalCode">Code Postal</label>
              <input
                type="text"
                id="postalCode"
                name="postalCode"
                value={reservationData.postalCode}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="city">Ville</label>
              <input
                type="text"
                id="city"
                name="city"
                value={reservationData.city}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Téléphone</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={reservationData.phone}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Adresse Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={reservationData.email}
                onChange={handleInputChange}
                required
              />
            </div>

            {/* Champs pour les détails de la réservation */}
            <div className="form-group">
              <label htmlFor="checkin">Date d'arrivée</label>
              <input
                type="date"
                id="checkin"
                name="checkin"
                value={reservationData.checkin}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="checkout">Date de départ</label>
              <input
                type="date"
                id="checkout"
                name="checkout"
                value={reservationData.checkout}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="guests">Nombre de personnes</label>
              <input
                type="number"
                id="guests"
                name="guests"
                value={reservationData.guests}
                onChange={handleInputChange}
                min="1"
                max="10"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="roomType">Type de chambre</label>
              <select
                id="roomType"
                name="roomType"
                value={reservationData.roomType}
                onChange={handleInputChange}
              >
                <option value="standard">Chambre double standard</option>
                <option value="jumeaux">Chambre lits jumeaux</option>
                <option value="familiale">Chambre familiale</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="bathroomChoice">Choix Sanitaire</label>
              <select id="bathroomChoice" name="bathroomChoice" value={reservationData.bathroomChoice} onChange={handleInputChange}>
                <option value="douche">Douche</option>
                <option value="bain">Bain</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="bedChoice">Choix de Lit</label>
              <select id="bedChoice" name="bedChoice" value={reservationData.bedChoice} onChange={handleInputChange}>
                <option value="1 lit">1 Lit</option>
                <option value="2 lits">2 Lits</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="breakfast">Nombre de Petit-déjeuners</label>
              <input
                type="number"
                id="breakfast"
                name="breakfast"
                value={reservationData.breakfast}
                onChange={handleInputChange}
                min="0"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="dinnerGuests">Dîner : 1 table pour combien de personnes ?</label>
              <input
                type="number"
                id="dinnerGuests"
                name="dinnerGuests"
                value={reservationData.dinnerGuests}
                onChange={handleInputChange}
                min="1"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="specialRequests">Demandes Spéciales</label>
              <textarea
                id="specialRequests"
                name="specialRequests"
                value={reservationData.specialRequests}
                onChange={handleInputChange}
                placeholder="Ex: allergie alimentaire, lit bébé, etc."
              ></textarea>
            </div>

            {loading && <div className="spinner"></div>}
            <button type="submit" className="submit-btn" disabled={loading}>
              {loading ? "Envoi en cours..." : "Réservation"}
            </button>
          </form>
        </div>
      </section>
    </>
  );
}

export default Reservation;
