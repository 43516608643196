import React from 'react';
import { Helmet } from 'react-helmet';

function Photos() {
 
  return (
    <>
    <Helmet>
     <title>Photos - Les Tilleuls</title>
     <meta name="description" content="Parcourez les différentes photos de notre charmant hôtel." />
     <meta property="og:title" content="Photos - Les Tilleuls" />
     <meta property="og:description" content="Des images de notres hôtel Les Tolleuls sous toutes ses coutures." />
     <meta property="og:image" content="/logo.png" />
   </Helmet>
 </>
  );
}

export default Photos;
