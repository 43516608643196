import React from 'react';
import { Helmet } from 'react-helmet';

function Restaurant() {
 
  return (
  <>
    <Helmet>
      <title>Restaurant - Les Tilleuls</title>
      <meta name="description" content="Découvrez notre restaurant dans les moindres détails." />
      <meta property="og:title" content="Restauration - Les Tilleuls" />
      <meta property="og:description" content="A table ! vous allez adorer nos plats typiques de la région Bourgogne-Franche-Comté." />
      <meta property="og:image" content="/logo.png" />
    </Helmet>
  </>
  );
}

export default Restaurant;
