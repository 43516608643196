import React from 'react';
import { Helmet } from 'react-helmet';

function Hotel() {
 
  return (
    <>
       <Helmet>
        <title>Hôtel - Les Tilleuls</title>
        <meta name="description" content="Tout ce que vous avez besoin de savoir sur nos services hôteliers." />
        <meta property="og:title" content="Hôtel - Les Tilleuls" />
        <meta property="og:description" content="Découvrez notre hôtel dans les moindres détails." />
        <meta property="og:image" content="/logo.png" />
      </Helmet>
    </>
  );
}

export default Hotel;
