import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink, useLocation } from 'react-router-dom';
import Homepage from './Homepage';
import Hotel from './Hotel';
import Reservation from './Reservation';
import Restaurant from './Restaurant';
import Region from './Region';
import Photos from './Photos';
import './App.css';
import { Helmet } from 'react-helmet';

// Composant Footer
function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo">
          <img src="/logo.webp" alt="Les Tilleuls Logo" className="footer-logo-img" />
        </div>
        <div className="footer-links">
          <ul>
            <li><NavLink to="/">Accueil</NavLink></li>
            <li><NavLink to="/hotel">Hôtel</NavLink></li>
            <li><NavLink to="/reservation">Réservation</NavLink></li>
            <li><NavLink to="/restaurant">Restaurant</NavLink></li>
            <li><NavLink to="/region">La région</NavLink></li>
            <li><NavLink to="/photos">Photos</NavLink></li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Les Tilleuls. Tous droits réservés.</p>
      </div>
    </footer>
  );
}

function AppContent() {
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const mainContentClass = location.pathname === '/' ? 'main-content homepage' : 'main-content';

  return (
    <>
      <Helmet>
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <button title="burger-Menu" className={`burger-menu ${isSidebarOpen ? 'open' : ''}`} onClick={toggleSidebar}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </button>

      <aside className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <div className="logo-container">
          <img src="/logo.webp" alt="Les Tilleuls Logo" className="logo" />
        </div>
        <nav className="navbar">
          <ul>
            <li className={location.pathname === '/' ? 'active' : ''}>
              <NavLink to="/" end>
                Accueil
              </NavLink>
            </li>
            <li className={location.pathname === '/hotel' ? 'active' : ''}>
              <NavLink to="/hotel">Hôtel <span className='temporary'>(prochainement)</span></NavLink>
            </li>
            <li className={location.pathname === '/reservation' ? 'active' : ''}>
              <NavLink to="/reservation">Réservation</NavLink>
            </li>
            <li className={location.pathname === '/restaurant' ? 'active' : ''}>
              <NavLink to="/restaurant">Restaurant <span className='temporary'>(prochainement)</span></NavLink>
            </li>
            <li className={location.pathname === '/region' ? 'active' : ''}>
              <NavLink to="/region">La région <span className='temporary'>(prochainement)</span></NavLink>
            </li>
            <li className={location.pathname === '/photos' ? 'active' : ''}>
              <NavLink to="/photos">Photos <span className='temporary'>(prochainement)</span></NavLink>
            </li>
          </ul>
        </nav>
        <div className="contact-info">
          <span>3 Rue Descourtives, 89600 Saint-Florentin</span>
          <br />
          <span><img height="16px" width="16px" alt="mail-icon" src="/mail.webp" />thomasevasion@gmail.com</span>
          <br />
          <span><img height="16px" width="16px" alt="phone-icon" src="/phone.webp" />03.86.35.09.09</span>
        </div>
      </aside>

      <main className={`${mainContentClass} ${isSidebarOpen ? 'shifted' : ''}`}>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/hotel" element={<Hotel />} />
          <Route path="/reservation" element={<Reservation />} />
          <Route path="/restaurant" element={<Restaurant />} />
          <Route path="/region" element={<Region />} />
          <Route path="/photos" element={<Photos />} />
        </Routes>
      </main>

      <Footer />
    </>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
